
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import SatCarousel from "@/components/carousel/SatCarousel.vue";
import CompetitionCard from "@/components/carousel/CompetitionCard.vue";
import TeamCard from "@/components/carousel/TeamCard.vue";
import SatCard from "@/components/carousel/SatCard.vue";
import HomeAwayScore from "@/components/HomeAwayScore.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { videos } from "@/_modules/mock";
import { Helpers } from "@/_modules/helpers";
import { homeService, teamService } from "@/_services";
import { useStore } from "vuex";
import BannerCompetition from "@/components/BannerCompetition.vue";

import {
    BaseResponse,
    IAthlete,
    ListVersusMatch,
    Match,
    ITeam,
    ICompetition,
    IOverview,
    IOverviewCompetition,
    IStaff,
    IOverviewTeam,
} from "@/_modules/types";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import useTeamRepositories from "@/_composables/useTeamRepositories";
import useAthleteRepositories from "@/_composables/useAthleteRepositories";
import useOverviewRepositories from "@/_composables/useOverviewRepositories";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
    name: "Overview",
    components: {
        BannerCompetition,

        SatIcon,
        SatCardCarousel,
        SatCarousel,
        SatCard,
        CompetitionCard,
        TeamCard,
        HomeAwayScore,
        VideoPlayer,
        LoadingOutlined,
    },
    setup() {
        const now = format(new Date(), "dd MMMM", { locale: th });
        const yearTh = new Date().getFullYear() + 543;
        const nowEn = format(new Date(), "dd MMMM");
        const yearEn = new Date().getFullYear();

        const updatedDate = ref(`${now} ${yearTh}`);
        const updatedDateEn = ref(`${nowEn} ${yearEn}`);

        const homeId = ref<string>();
        const awayId = ref<string>();
        const visibleMoreSection = ref(false);
        const store = useStore();
        const loadings = ref(false);
        const helpers = Helpers;
        const state = reactive({
            language: null,
            loading: { first: true, athlete: true, staff: true },
            latestMatch: {
                id: {
                    competitionId: "",
                    subCompetitionId: "",
                    competitionSectionId: "",
                    versusMatchId: "",
                },
                tournament: {
                    logo: require("@/assets/reward-placeholder-gray.svg?raw"),
                    round: {
                        en: "",
                        th: "",
                    },
                },
                homeTeam: {
                    name: {
                        th: "",
                        en: "",
                    },
                    logo: require("@/assets/logo-placeholder-brown.svg?raw"),
                    score: 0,
                },
                awayTeam: {
                    name: {
                        th: "",
                        en: "",
                    },
                    logo: require("@/assets/logo-placeholder-brown.svg?raw"),
                    score: 0,
                },
                date: {
                    th: "",
                    en: "",
                },
                startTime: "19.45",
                endTime: "20.45",
            },
            matches: [
                {
                    score: { home: 2, away: 1 },
                    matchName: {
                        en: "",
                        th: "",
                    },
                    home: { logo: require("@/assets/mock/thailand.svg"), name: null },
                    away: { logo: require("@/assets/mock/japan.svg"), name: null },
                    id: {
                        competitionId: "",
                        subCompetitionId: "",
                        competitionSectionId: "",
                        versusMatchId: "",
                    },
                },
            ] as any,
            videos: [...videos],
            video: {
                source:
                    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                poster:
                    "https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217",
            },
            images: [...new Array(8)].map(() => helpers.getRandomInt(10, 120)),
            athletes: {} as IOverview,
            staffs: {} as IOverview,
            athleteInjuries: {} as IOverview,
            teams: {} as IOverviewTeam,
            total: 0 as number,
            competitions: {} as IOverviewCompetition,
        });
        const {
            getOverviewAthlete,
            getOverviewStaff,
            getOverviewTeams,
            getTotalOverviewTeam,
            getOverviewCompetition,
            getOverviewMatchCompetition,
        } = useOverviewRepositories();
        onMounted(async () => {
            const versusMatch = await getOverviewMatchCompetition({
                page: 1,
                size: 10,
                competitions: false,
                latest: true,
            });
            if (versusMatch) {
                formatVersusMatch(versusMatch);
            }
            state.athletes = (await getOverviewAthlete({
                page: 1,
                size: 10,
                injuries: false,
            })) as IOverview;
            state.loading.athlete = false;
            state.staffs = (await getOverviewStaff({
                page: 1,
                size: 10,
            })) as IOverview;

            console.log('staffs', state.staffs.data)
            state.loading.staff = false;
        });

        const loadMore = async () => {
            loadings.value = true;

            state.athleteInjuries = (await getOverviewAthlete({
                page: 1,
                size: 10,
                injuries: true,
            })) as IOverview;
            state.total = (await getTotalOverviewTeam({
                page: 1,
                size: 10,
                global_config_local_types: 1,
                total: true,
            })) as number;

            state.teams = (await getOverviewTeams({
                page: 1,
                size: 10,
            })) as IOverviewTeam;

            state.competitions = (await getOverviewCompetition({
                page: 1,
                size: 10,
                competitions: true,
                latest: false,
            })) as IOverviewCompetition;
            visibleMoreSection.value = true;

            loadings.value = false;
        };

        const formatVersusMatch = (versusMatch: any) => {
            state.loading.first = false;
            state.latestMatch.homeTeam = {
                name: {
                    th: versusMatch?.latest_versus_match?.versus_match_team_detail_one_id
                        ?.versus_match_team_name,
                    en: versusMatch?.latest_versus_match?.versus_match_team_detail_one_id
                        ?.versus_match_team_name_en,
                },
                logo: versusMatch?.latest_versus_match?.versus_match_team_detail_one_id
                    ?.versus_match_team_image,
                score:
                    +versusMatch?.latest_versus_match?.versus_match_team_detail_one_id
                        ?.versus_match_score,
            };
            state.latestMatch.awayTeam = {
                name: {
                    th: versusMatch?.latest_versus_match?.versus_match_team_detail_two_id
                        ?.versus_match_team_name,
                    en: versusMatch?.latest_versus_match?.versus_match_team_detail_two_id
                        ?.versus_match_team_name_en,
                },
                logo: versusMatch?.latest_versus_match?.versus_match_team_detail_two_id
                    ?.versus_match_team_image,
                score:
                    +versusMatch?.latest_versus_match?.versus_match_team_detail_two_id
                        ?.versus_match_score,
            };
            state.latestMatch.id = {
                competitionId:
                    versusMatch?.latest_versus_match?.setting_sub_competition
                        ?.setting_competition_id,
                subCompetitionId:
                    versusMatch?.latest_versus_match?.setting_sub_competition?.id,
                competitionSectionId:
                    versusMatch?.latest_versus_match?.competition_sections?.id,
                versusMatchId: versusMatch?.latest_versus_match?.id,
            };
            const splitDate =
                versusMatch?.latest_versus_match?.versus_match_date.split("-");

            if (splitDate) {
                const date = new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2]);
                const result = date.toLocaleDateString("th-TH", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
                const resultEn = date.toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
                state.latestMatch.date = {
                    en: resultEn,
                    th: result,
                };
            }
            const timeStart =
                versusMatch?.latest_versus_match?.versus_match_start_at.split(":");
            const timeEnd =
                versusMatch?.latest_versus_match?.versus_match_end_at.split(":");

            if (timeStart) {
                state.latestMatch.startTime = `${timeStart[0]}.${timeStart[1]}`;
            }
            if (timeEnd) {
                state.latestMatch.endTime = `${timeEnd[0]}.${timeEnd[1]}`;
            }
            state.latestMatch.tournament = {
                logo: versusMatch?.latest_versus_match?.setting_sub_competition
                    ?.setting_sub_competition_media_profile_url,
                round: {
                    en: versusMatch?.latest_versus_match?.competition_sections
                        ?.competition_section_name_en,
                    th: versusMatch?.latest_versus_match?.competition_sections
                        ?.competition_section_name_th,
                },
            };

            const listMatch = versusMatch?.list_versus_match.map(
                (element: any, idx: number) => {
                    const {
                        versus_match_team_detail_one_id: home,
                        versus_match_team_detail_two_id: away,
                        setting_sub_competition,
                    } = element;
                    return {
                        score: {
                            home: home?.versus_match_score,
                            away: away?.versus_match_score,
                        },
                        matchName: {
                            th: setting_sub_competition?.setting_sub_competition_name_th,
                            en: setting_sub_competition?.setting_sub_competition_name_en,
                        },
                        home: {
                            logo: home?.versus_match_team_image,
                            name: {
                                th: home?.versus_match_team_name ?? "-",
                                en: home?.versus_match_team_name_en ?? "-",
                            },
                        },
                        away: {
                            logo: away.versus_match_team_image,
                            name: {
                                th: away.versus_match_team_name ?? "-",
                                en: away.versus_match_team_name_en ?? "-",
                            },
                        },
                        id: {
                            competitionId:
                                element?.setting_sub_competition?.setting_competition_id,
                            subCompetitionId: element?.setting_sub_competition?.id,
                            competitionSectionId: element?.competition_sections?.id,
                            versusMatchId: element?.id,
                        },
                    };
                }
            );
            state.matches = listMatch;
        };
        const { t, locale } = useI18n({
            useScope: "global",
        });

        const onClickVideoInPlaylist = (video: typeof videos[0]) => {
            state.video.source = video.sources[0];
            state.video.poster = `https://picsum.photos/id/${video.thumb.length}/200/100`;
        };
        return {
            t,
            ...toRefs(state),
            onClickVideoInPlaylist,
            helpers,
            loadMore,
            // competitions,
            // teams,
            loadings,
            visibleMoreSection,
            store,
            updatedDate,
            updatedDateEn,
        };
    },
});
