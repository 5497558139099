import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Overview from '../views/Overview.vue'
import SSO from '../views/SSO.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sso',
    name: 'sso',
    component: SSO
  },
  {
    path: '/',
    name: 'Overview',
    component: Overview,
    meta: {
      visible: true,
      name: 'menu.overview'// 'ภาพรวม'
    }
  },
  {
    path: '/athletes',
    name: 'Athlete',
    component: () => import(/* webpackChunkName: "athletes" */ '../views/athlete/Athletes.vue'),
    meta: {
      visible: true,
      name: 'menu.athlete'// 'นักกีฬา'
    }
  },
  {
    path: '/athletes/:id',
    component: () => import(/* webpackChunkName: "athlete" */ '../views/athlete/Athlete.vue')
  },
  {
    path: '/teams',
    name: 'Teams',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "teams" */ '../views/team/Teams.vue'),
    meta: {
      visible: true,
      name: 'menu.team'// 'ทีม/สโมสร'
    }
  },
  {
    path: '/teams/:id',
    component: () => import(/* webpackChunkName: "team" */ '../views/team/Team.vue')
  },
  {
    path: '/staffs',
    name: 'Staff',
    component: () => import(/* webpackChunkName: "staffs" */ '../views/staff/Staffs.vue'),
    meta: {
      visible: true,
      name: 'menu.staff'// 'บุคลากร'
    }
  },
  {
    path: '/staffs/:id',
    component: () => import(/* webpackChunkName: "athlete" */ '../views/staff/Staff.vue')
  },
//   {
//     path: '/stadiums',
//     name: 'Stadium',
//     component: () => import(/* webpackChunkName: "stadiums" */ '../views/stadium/Stadiums.vue'),
//     meta: {
//       visible: true,
//       name: 'menu.stadium'// 'สนามกีฬา'
//     }
//   },
  {
    path: '/stadiums/:id',
    component: () => import(/* webpackChunkName: "stadium" */ '../views/stadium/Stadium.vue')
  },
  {
    path: '/competitions',
    name: 'Competition',
    component: () => import(/* webpackChunkName: "competitions" */ '../views/competition/Competitions.vue'),
    meta: {
      visible: true,
      name: 'menu.competition' // 'รายการแข่งขัน'
    }
  },
  {
    path: '/competitions/:id/:subId',
    component: () => import(/* webpackChunkName: "subcompetition-overview" */ '../views/competition/SubCompetition.vue')
  },
  {
    path: '/competitions/:id/:subId/:competitionId/:versusId',
    component: () => import(/* webpackChunkName: "subcompetition-overview" */ '../views/competition/CompetitionVersus.vue')
  },
  {
    path: '/competitions/:id',
    component: () => import(/* webpackChunkName: "competition-overview" */ '../views/competition/CompetitionOverview.vue')
  },
  {
    path: '/disallow',
    name: 'Disallowed',
    component: () => import(/* webpackChunkName: "disalllow" */'../views/Disallowed.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
